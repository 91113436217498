// 通用路由: 404 要放在最后
export default [
  {
    path: '/complete-order',
    name: 'complete-order',
    component: () => import('@/views/home/ai-complete-order'),
    meta: {
      title: '补充订单',
    },
  },
  {
    path: '/*',
    redirect: '/',
    name: 'page404',
    component: () => import('@/views/404'),
    meta: {
      title: '404-页面不存在',
    },
  },
]
