// import { debounce } from 'lodash'
import { Message } from 'view-design'
import * as api from '@/api'
// import wj from '@/plugins/wjtools'

export default {
  // 货物类型选项列表
  async getGoodsTypeList({ commit }) {
    const res = await api.aiGoodsTypes()
    if (!res.success) {
      Message.error(res.msg || res.message)
      return
    }
    const list = (res.data || []).map(item => ({
      value: item.code,
      label: item.nameCN,
    }))
    commit('setGoodsTypeList', list)
  },

  // 货物类型选项列表
  async getPlatformLabelList({ commit }) {
    const res = await api.channelLabelOptions()
    if (+res.code !== 200) {
      Message.error(res.msg || res.message)
      return
    }
    const list = (res.data || []).map(item => ({
      value: item.code,
      label: item.nameCN,
    }))
    commit('setPlatformLabelList', list)
  },
}
