<template>
  <RouterView v-if="isRouterAlive" id="app" class="app" />
</template>

<script>
  import { mapActions, mapMutations } from 'vuex'

  export default {
    data() {
      return {
        isRouterAlive: true,
      }
    },

    created() {
      // 接收聊天窗口消息并执行相应方法
      window.addEventListener('message', (event) => {
        const action = this[event.data?.action]
        if (typeof action === 'function') action()
      })
    },

    methods: {
      ...mapActions('app', ['getInfoList']),
      ...mapMutations('app', ['setShowIframe', 'setNewMessage']),

      // 刷新页面
      reload() {
        this.isRouterAlive = false
        this.$nextTick(() => (this.isRouterAlive = true))
      },

      // 关闭聊天窗口
      closeChatWindow() {
        this.setNewMessage(0)
        this.getInfoList()
        this.setShowIframe(false)
      },

      linkToMessagePage() {
        this.closeChatWindow()
        this.$router.push('/my-center/message')
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .app
    position relative
    box-sizing border-box
    min-height 100%
    color $black
    min-width $design-width
    clearfix() // 触发 BFC
    background $bgcolor
</style>
