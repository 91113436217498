// 个人中心页面路由
const baseName = 'my-center'

export default [
  {
    path: '/my-center',
    component: () => import('@/views/layout'),
    children: [
      {
        path: '',
        name: `${baseName}-order`,
        component: () => import('@/views/my-center/order'),
        meta: {
          needAuth: true,
          title: '我的订单',
          showSideber: true
        }
      },
      {
        path: 'vip-rights',
        name: `${baseName}-vip-rights`,
        component: () => import('@/views/my-center/vip-rights'),
        meta: {
          needAuth: true,
          title: '会员权益',
          showSideber: true
        }
      },
      {
        path: 'account',
        name: `${baseName}-account`,
        component: () => import('@/views/my-center/account'),
        meta: {
          needAuth: true,
          title: '工作台',
          showSideber: true
        }
      },
      {
        path: 'account/change-password',
        name: `${baseName}-change-password`,
        component: () => import('@/views/my-center/account/change-password.vue'),
        meta: {
          needAuth: true,
          title: '修改登录密码'
        }
      },
      {
        path: 'invite',
        name: `${baseName}-invite`,
        component: () => import('@/views/my-center/invite'),
        meta: {
          needAuth: true,
          title: '邀请有奖'
        }
      },
      {
        path: 'goods',
        name: `${baseName}-goods`,
        component: () => import('@/views/my-center/goods'),
        meta: {
          needAuth: true,
          title: '商品管理',
          showSideber: true
        }
      },
      {
        path: 'address',
        name: `${baseName}-address`,
        component: () => import('@/views/my-center/address'),
        meta: {
          needAuth: true,
          title: '地址管理',
          showSideber: true
        }
      },
      {
        path: 'reparation',
        name: `${baseName}-reparation`,
        component: () => import('@/views/my-center/reparation'),
        meta: {
          needAuth: true,
          title: '赔付记录',
          showSideber: true
        }
      },
      {
        path: 'wallet',
        name: `${baseName}-wallet`,
        component: () => import('@/views/my-center/wallet'),
        meta: {
          needAuth: true,
          title: '我的钱包',
          showSideber: true
        }
      },
      {
        path: 'invoice',
        name: `${baseName}-invoice`,
        component: () => import('@/views/my-center/invoice'),
        meta: {
          needAuth: true,
          title: '发票管理',
          showSideber: true
        }
      },
      {
        path: 'subsidy-center',
        name: `${baseName}-subsidy-center`,
        component: () => import('@/views/my-center/subsidy/index'),
        meta: {
          needAuth: true,
          title: '补贴中心',
          showSideber: true
        }
      },
      {
        path: 'subsidy-history',
        name: `${baseName}-subsidy-history`,
        component: () => import('@/views/my-center/subsidy/history'),
        meta: {
          needAuth: true,
          title: '补贴金-历史记录',
          showSideber: true
        }
      },
      {
        path: 'invoice/addInvoice',
        name: `${baseName}-addInvoice`,
        component: () => import('@/views/my-center/invoice/invoice'),
        meta: {
          needAuth: true,
          title: '我要开票'
        }
      },
      {
        path: 'coupon',
        name: `${baseName}-coupon`,
        component: () => import('@/views/my-center/coupon'),
        meta: {
          needAuth: true,
          title: '我的补贴券',
          showSideber: true
        }
      },
      {
        path: `/${baseName}/message`,
        name: `${baseName}-message`,
        component: () => import('@/views/my-center/message'),
        meta: {
          needAuth: true,
          title: '消息中心',
          showSideber: true
        }
      },
      {
        path: `/${baseName}/should-pay`,
        name: `${baseName}-should-pay`,
        component: () => import('@/views/my-center/financial-management/should-pay'),
        meta: {
          needAuth: true,
          title: '应付账单',
          showSideber: true
        }
      },
      {
        path: `/${baseName}/account-setting`,
        name: `${baseName}-account-setting`,
        component: () => import('@/views/my-center/financial-management/account-setting'),
        meta: {
          needAuth: true,
          title: '账号设置',
          showSideber: true
        }
      },
      {
        path: `/${baseName}/payment-days`,
        name: `${baseName}-payment-days`,
        component: () => import('@/views/my-center/financial-management/payment-days'),
        meta: {
          needAuth: true,
          title: '我的账期',
          showSideber: true
        }
      },
      {
        path: 'logistics-set',
        name: `${baseName}-logistics-set`,
        component: () => import('@/views/my-center/logistics-set'),
        meta: {
          needAuth: true,
          title: '物流短信配置',
          showSideber: true
        }
      },
    ]
  }
]
