import cookie from './cookie'
import router from '@/router'
import store from '@/store'

// 用户是否登录
export function isLogin() {
  return !!cookie('username') && !!cookie('token')
}

// 转到登录页
export function login(onlogin) {
  store.commit('app/setShowLogin', true)
  store.commit('app/setOnlogin', onlogin)
}

// 清除登录态
export function logout(callback = () => {}) {
  cookie('token', '', { path: '/' })
  cookie('username', '', { path: '/' })
  cookie('userid', '', { path: '/' })
  cookie('token', '', { path: '/my-center' })
  cookie('username', '', { path: '/my-center' })
  cookie('token', '', { path: '/events' })
  cookie('username', '', { path: '/events' })
  // cookie('remeber', '', { path: '/' })
  store.commit('app/upLoginState')
  const path = router.currentRoute.path
  const query = router.currentRoute.query
  sessionStorage.setItem('loginPath', path) // 存当前路由
  if (JSON.stringify(query) !== '{}') sessionStorage.setItem('loginQuery', JSON.stringify(query)) // 存当前路由query
  sessionStorage.removeItem('showEvent')
  sessionStorage.removeItem('orderNotice')
  sessionStorage.removeItem('loginActive')
  callback()
}

// 设置登录态缓存：默认保存 90 天
export function setLoginCookie({ token, username = '', expires = 90 }) {
  const opts = { expires, path: '/' }
  cookie('username', username, opts)
  cookie('token', token, opts)
  sessionStorage.setItem('loginActive', 'active')
  store.commit('app/upLoginState') // 更新登录态
  store.dispatch('app/websocketConnect') // 建立 WS 连接并展示 IM
  store.dispatch('user/getUserid', opts) // 获取并缓存用户 ID
}

// 更新登录态缓存
export function upLoginCookie() {
  if (!isLogin()) return
  setLoginCookie({
    token: cookie('token'),
    username: cookie('username'),
  })
}

// 是否为好运管家用户
export function isSaasUser() {
  return isLogin() && cookie('isSaasUser')
}
