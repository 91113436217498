import Vue from 'vue'
import 'spinkit/spinkit.css'
import './styles/iview.less'
import './styles/main.styl'
import stat from 'vue-stat'
import App from './app'
import { sync } from 'vuex-router-sync'
import store from './store'
import router from './router'
import filters from './filters'
import { assets } from './config'
import wj from './plugins/wjtools'
import istat from './plugins/istat'
import * as api from './api'
import './plugins/iview'
import './plugins/vxe-table'
import './plugins/validator'
import './plugins/auto-animate'
import vueScrollwatch from 'vue-scrollwatch'
import checkView from 'vue-check-view'

Vue.config.productionTip = false // 关闭启动时生成生产提示
Vue.config.ignoredElements = [/^wx-open-/]

sync(store, router)
filters(Vue) // 注册全局过滤器
Vue.use(vueScrollwatch)
Vue.use(checkView)
Vue.prototype.$wj = wj
Vue.prototype.$util = wj.util
Vue.prototype.$api = api
Vue.prototype.$assets = assets
Vue.prototype.$isInSaasIframe = false
Vue.prototype.$bus = new Vue()

// 初始化 istat 统计
Vue.use(istat, {
  // url: 'https://log.jcex.cn/log.gif',
  // source: '1', // 1-web端；2-合伙人web端；3-小程序
  // debug: !isOnline
  debug: true
})

// 初始化百度统计
Vue.prototype.$stat = stat // 百度统计实例
Vue.use(stat, {
  // debug: !isOnline,
  siteId: 'cde926e331089037081dd7e7f1c5369f',
  autoPageview: false,
})

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
