// import wj from '@/plugins/wjtools'

export default {
  setGoodsTypeList (state, payload) {
    state.goodsTypeList = payload
  },
  setPlatformLabelList (state, payload) {
    state.platformLabelList = payload
  },
}
