import Vue from 'vue'
import Router from 'vue-router'
import { LoadingBar } from 'view-design'
import stat from 'vue-stat'
import wj from '@/plugins/wjtools'

import home from './home'
import myCenter from './my-center'
import common from './common'

Vue.use(Router)

const appLoading = document.querySelector('#app-loading')

// 修改路由 push 方法，禁止跳转相同路由时报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 创建路由实例
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    // 修改 query 参数时页面不滚动
    const isSamePage = to.path === from.fullPath
    return isSamePage ? {} : savedPosition || { x: 0, y: 0 }
  },
  routes: [
    ...home,
    ...myCenter,
    ...common
  ]
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
  appLoading.style.display = 'flex' // 显示加载动画
  LoadingBar.start()

  // 路由报错刷新处理
  const targetPath = localStorage.getItem('router:targetPath')
  if (targetPath) {
    localStorage.removeItem('router:targetPath')
    const reloadCount = sessionStorage.getItem('router:reloadCount')
    if (!reloadCount) {
      router.replace(targetPath)
      sessionStorage.setItem('router:reloadCount', 1)
      return
    }
  }

  // 缓存登录态
  const { token, username } = to.query
  if (token) {
    const expires = to.query.from === 'crm' ? 2 / 24 : 90 // ofc 补充订单登录态缓存 2 小时
    wj.setLoginCookie({ token, username, expires })
  }

  // 登录权限控制
  if (to.meta.needAuth && !wj.isLogin()) {
    LoadingBar.finish()
    router.replace('/')
    wj.login()
    return
  }

  next()
})

// 全局后置钩子
router.afterEach((to, from) => {
  appLoading.style.display = 'none' // 隐藏加载动画
  LoadingBar.finish()

  if (to.meta.title) document.title = to.meta.title

  window.scrollTo(0, 0)

  sessionStorage.removeItem('router:reloadCount')

  stat.trackPageview(to.fullPath) // 百度统计
})

// 监听路由报错
router.onError((err) => {
  // 路由报 Loading chunk {n} failed 时刷新页面，尝试刷新两次
  // 必须用 reload 刷新，其他方式刷新，请求的依然是原来的资源
  const reg = /Loading (\w+ )?chunk (\S)+ failed/ig
  const isChunkLoadFailed = reg.test(err.message)
  if (isChunkLoadFailed) {
    const reloadCount = +sessionStorage.getItem('router:reloadCount')
    const targetPath = router.history.pending.fullPath
    if (reloadCount < 2) {
      localStorage.setItem('router:targetPath', targetPath)
      sessionStorage.setItem('router:reloadCount', reloadCount + 1)
      location.reload()
      return
    }
    sessionStorage.removeItem('router:reloadCount')
  }
})

// push 方法添加新页面打开功能
const routerPush = router.push
router.push = (param) => {
  if (param.blank) {
    const { href } = router.resolve(param)
    window.open(href, '_blank')
  } else {
    routerPush.call(router, param)
  }
}

export default router
