// js 通用工具 by steven 2017/12/21
import config from '@/config'
import env, { device } from './env'
import { getParam, setParam, delParam, setParams, getQuery } from './location'
import cookie from './cookie'
import { store, session } from './storage'
import { ajax, get, post, put, del, upload, xhrs } from './ajax'
import { isLogin, login, logout, setLoginCookie, upLoginCookie, isSaasUser } from './user'
import * as dom from './dom'
import * as util from './util'
import * as Browser from './check-brower-type.js'

let wjtools = {
  // 通用配置
  version: config.version,
  config,

  // 环境变量
  env,
  device,

  // 通用工具
  noop: util.noop,
  util,

  // 常用工具
  getParam,
  setParam,
  delParam,
  setParams,
  getQuery,

  // 判断浏览器类型
  Browser,

  // 数据存储
  cookie,
  store,
  session,

  // UI 组件
  // loading,
  // hideLoading,
  // alert,
  // toast,
  // confirm,
  // tips,
  // notify,

  // ajax
  ajax,
  get,
  post,
  put,
  del,
  upload,
  xhrs,

  // 用户相关
  isLogin,
  login,
  logout,
  setLoginCookie,
  upLoginCookie,
  isSaasUser,

  // 微信相关
  // getWxCode,

  // hack
  // setTitle,

  // 扩展属性/方法
  extend(obj) {
    return util.deepClone(this, obj)
  }
}

// 扩展 DOM 方法
wjtools.extend(dom)

export default wjtools
