// 接口列表
import config from '@/config'
import http from '@/plugins/wjtools'
const apimock = 'https://mock.apifox.com/m1/650141-0-default/' // eslint-disable-line

// 验证手机号是否注册|post
export const verifyRegister = params => http.post('kjhy-account/validate/phoneNumber', params)

// 登录|post
export const login = params => http.post('kjsc-user/user/login', params)

// 退出登录|get
export const logout = params => http.get('kjsc-user/loginout', params)

// 注册|post
export const register = params => http.post('kjsc-user/user/register', params)

// 获取图片验证码|get
export const getImgCode = params => http.get('kjsc-user/imgValidate', params)

// 验证图片验证码并发送短信|post
export const verifyImgCode = params => http.post('kjsc-user/imgValidate', params)

// 找回密码发送验证码|get
export const findPasswordGetMsgCode = params => http.get('kjsc-user/user/resetCode/' + params)

// 找回密码验证手机号|post
export const findPassword = params => http.post('kjsc-user/user/verification', params)

// 找回密码设置新密码|post
export const findPasswordSetPwd = params => http.post('kjhy-account/user/password', params)

// 发送短信验证码|get
export const sendSmsCode = params => http.get('smsValidate', params)

// 是否为SaaS用户|get
// export const isSaasUser = params => http.get(`${uniclound}/kjapi-saas/is-saas-user`, params)
export const isSaasUser = params => http.get('kjsc-user/h5/me/saas/isOpen')

// 首页搜索报价|post
export const searchList = params => http.post('charge-calculate', params)

// 付款前-校验保价物品价值|get
export const guaranteeValidate = params => http.get('kjhy-price/guarantee/validate', params)

// 付款前-下单前算预估价|post
export const calculateForCreateOrder = params => http.post('kjsc-solution/h5/me/channel/calculate/for-create-order', params)

// 获取下单时候的各种
export const calculateDetail = params => http.post('kjsc-solution/h5/me/channel/calculate/detail', params)

// 首页-获取发货城市列表|get
// export const getSendCityList = params => http.get('delivery/city', params)
export const getSendCityList = params => http.get('kjsc-basedata/h5/out/channel-city', params)

// 首页-获取报价信息|post
export const getOfferInfo = params => http.post('charge-calculate/detail', params)

// 首页-起运城市|get
export const chineseCityList = params => http.get('kjsc-basedata/h5/out/all/chineseCity', params)

// 首页-获取活动列表|get
export const getActiveList = params => http.get(config.kjcmsUrl + '?m=home&c=Article&a=lists&json=1&page=1&size=100&tid=68')

// 首页-提交联系我们表单|get
export const submitContactForm = params => http.get('kjsc-sales/h5/crm/contact-us', params)

// 获取FBA仓库列表|get
export const getFbaList = countryCode => http.get('kjsc-transportation/h5/me/fba-warehouse/select-box', { countryCode })
export const getFbaListParams = params => http.get('kjsc-transportation/h5/me/fba-warehouse/select-box', params)

// 商品下拉|post
export const getProductSelect = params => http.post('kjsc-order/h5/me/product/selectBox', params)

// 询价页-获取分享链接key|post
export const getShareAddressKey = params => http.post('kjsc-solution/h5/me/shareAddress/produce', params)

// 询价页-获取分享链接参数|post
export const getShareParams = params => http.post('kjsc-solution/h5/me/shareAddress/consumer', params)

// 询价页-获取广告分享链接参数|get
export const getAdShareParams = params => http.get('kjsc-marketing/www/adinfo/detail', params)

// 获取国家列表|get
export const getCountryList = params => http.get('countrys', params)

// 根据关键词-洲国家模糊查询|get
export const getCitiesByKeywords = params => http.get('kjsc-solution/h5/out/fuzzy-query-country/by-city-id', params)

// 根据关键词-中国城市模糊查询|get
export const getChineseCitiesByKeywords = params => http.get('kjsc-basedata/h5/out/fuzzyQueryCity', params)

// 根据发货城市-获取渠道|get
export const getChannelClassList = params => http.get('kjsc-solution/h5/out/channel-class/by-from-channel-type', params)

// 根据发货城市-获取国家列表|get
export const getCountryByCity = params => http.get('kjsc-solution/h5/out/channel-country/by-from-address-id', params)

// 获取省份列表|get
export const getProvinceList = params => http.get('provinces/' + params)

// 获取城市列表|get
export const getCityList = params => http.get('citys/' + params)

// 查询是否是偏远地区或者拒收|get
export const getIsFaraway = params => http.get('kjsc-solution/h5/me/check-remote', params)

// 个人中心-我的订单-编辑自有单号|put
export const customerIdChange = params => http.put('kjsc-order/h5/me/order/customer-id-change', params)

// 个人中心-我的订单-获取用户的各种状态订单的统计数据|get
export const orderSummaryList = params => http.get('kjsc-order/order/summary/items', params)

// 首页弹窗接口|get
export const commentDialog = params => http.get('kjsc-order/order/comment/dialog', params)

// 获取方案信息
export const getLibChannelInfo = params => http.get('kjsc-solution/h5/me/lib/channel', params)

// 评论-点赞|post
export const commentLike = params => http.post('kjsc-advisor/h5/me/order/comment/like', params)

// 评论-浏览|get
export const commentView = params => http.get('kjsc-advisor/h5/me/order/comment/view', params)

// 评论列表|post
export const commentListV2 = params => http.post('kjsc-advisor/h5/common/order/comment/list/v2', params)

// 评论列表-我的评论|post
export const myCommentListV2 = params => http.post('kjsc-advisor/h5/me/order/comment/list/v2', params)

// 收藏夹—列表|post
export const favoritesList = params => http.post('kjsc-solution/www/favorites/list', params)

// 收藏夹—新增|post
export const favoritesInsert = params => http.post('kjsc-solution/www/favorites/insert', params)

// 收藏夹—删除|delete
export const favoritesDelete = params => http.del('kjsc-solution/www/favorites/delete', params)

// 个人中心-我的订单-获得评价详情|get
export const orderCommentInfo = params => http.get('kjsc-advisor/h5/me/order/comment/info', params)

// 个人中心-我的订单-发起评价接口|post
export const orderCommentSubmit = params => http.post('kjsc-advisor/h5/me/order/comment', params)

// 个人中心-我的订单|post
export const getMyOrder = params => http.post('kjsc-order/h5/me/order/list', params, { abortPrev: true })

// 个人中心-我的订单-订单支付记录|get
// export const getOrderPayList = params => http.get('order/pay-list', params)
export const getOrderPayList = params => http.get('kjsc-order/client/order/pay-list', params)

// 个人中心-我的订单-费用记录|get
export const orderSaleFeeList = params => http.get('kjsc-order/h5/me/order-sale-fee/list', params)

// 订单管理-订单费用调整-费用调整记录
export const feeChangeLogList = params => http.get('kjsc-order/h5/me/order-fee/records', params)

// 个人中心-我的订单-支付状态|get
export const getPayStatusList = params => http.get('kjsc-basedata/order/payStatus/items', params)

// 个人中心-我的订单-订单状态|get
export const getOrderStatusListNew = params => http.get('kjsc-order/order/orderSumStatus/items', params)

// 个人中心-我的订单-订单状态|get
export const getOrderStatusList = params => http.get('kjsc-order/order/orderStatus/items', params)

// 个人中心-订单详情|get
// export const getOrderDetail = params => http.get('orderDetail/' + params)
export const getOrderDetail = params => http.get('kjsc-order/order/orderDetail/' + params)

// 个人中心-订单-设置送仓单号|post
export const updateSendWarehouseNo = params => http.post('kjsc-order/h5/order/details/sendWarehouseNo/update', params)

// 个人中心-订单物流信息|get
export const getOrderStatusInfo = params => http.get('tracking/' + params)

// 个人中心-订单物流信息-末端配送信息|get
export const transportationTrackInfo = params => http.get('kjsc-transportation/www/track/info', params)

// 个人中心-订单物流信息-详情|get
export const getOrderStatusInfoDetails = params => http.get('https://51.kjhaoyun.com/api/es/getInfo', params)

// 个人中心-订单物流信息-详情|get
export const queryExpressByOrderId = params => http.get('kjsc-transportation/h5/express/queryByOrderId', params)

// 个人中心-取消订单|post
// export const cancelOrder = params => http.post('order/cancel', params)
export const cancelOrder = params => http.post('kjsc-order/h5/me/order/cancel', params)

// 个人中心-我的账号待-付款金额付款|post
export const payDeductFee = params => http.post('center/account/pay', params)

// 个人中心-订单获取用户账户余额和费用信息|get
export const getUserFeeInfo = params => http.get('order/charge/' + params)

// 个人中心-订单扣款并确认发货|post
export const sureOrderAndSend = params => http.post('order/pay', params)

// 个人中心-我的订单-打印面单|get
// export const printPage = params => http.get('label/' + params)
export const printPage = params => http.get('order/label', params)

// 个人中心-我的的账号|get
export const getMyAccount = params => http.get('kjsc-user/center/account/index', params)

// 个人中心-我的的账号-开通、关闭自动扣款|post
export const setAutoDeduct = params => http.post('center/account/auto-deduction', params)

// 个人中心-我的的账号-修改密码|post
export const changePwd = params => http.post('kjhy-account/center/modify-password', params)

// 获取商品列表列表|get
export const getSkuList = params => http.get('kjsc-order/h5/me/product/query', params)

// 修改商品|post
export const editGoods = (params, config) => http.post('kjsc-order/h5/me/product/update', params, config)

// 新增商品|post
export const addGoods = (params, config) => http.post('kjsc-order/h5/me/product/create', params, config)

// 删除商品|post
export const deleteGoods = params => http.post('kjsc-order/h5/me/product/delete/' + params)

// 批量上传|post
export const uploadSku = (params, config) => http.post('kjsc-order/h5/me/product/upload/excel', params, config)

// 上传认证文件|post
export const uploadVerifyFile = (params, config) => http.post('kjsc-order/h5/me/product/upload/auth-file', params, config)

// 补充货物信息|post
export const fillOrderInfo = (params, config) => http.post('order/supplementaryInformation', params, config)

// 获取服务器时间|get
export const getServerTime = params => http.get('server/time', params)

// ====================================================================== 个人中心

// 微信支付-获取微信二维码|get
export const getWxPayQrcode = params => http.get('wxcatpay', params)

// 钱包-获取交易记录|post
export const getWalletRecords = params => http.post('kjsc-settlement/h5/me/transaction/query', params)

// 钱包-充值|post
export const deposit = params => http.post('center/account/deposit', params)

// 钱包-充值 -- 对公转账申请|post
// export const publictranApply = params => http.post('newapi/publictran/apply', params)
export const publictranApply = params => http.post('kjsc-settlement/h5/me/publicTran/apply', params)

// 钱包-用户其它收入列表|get
export const getOtherReward = params => http.get('wallet/otherReward', params)

// 钱包-提现|post
// export const withdraw = params => http.post('center/withdraw', params)
// export const withdraw = (params, config) => http.post('newapi/withdraw/user/withdraw', params, config)
export const withdraw = (params, config) => http.post('kjsc-settlement/withdraw/user/withdraw', params, config)

// 钱包-提现获取验证码|get
// export const getWithdrawCode = params => http.get('center/withdraw/smscode', params)
export const getWithdrawCode = params => http.get('kjsc-settlement/withdraw/smscode', params)

// 实名认证|post
export const verifyRealName = (params, config) => http.post('kjsc-user/center/name/authentication', params, config)

// 获取提现费率|get
// export const withDrawRate = (params, config) => http.get('center/withdraw/rates', params, config)
export const withDrawRate = (params, config) => http.get(`kjsc-settlement/withdraw/rates/${params}`, null, config)

// 个人中心-我的账单-获取列表|get
export const getBillList = params => http.get('kjsc-settlement/h5/me/bill/query', params)

// 个人中心-我的账单-账单待付款金额|get
export const getBillWaitPay = params => http.get('kjsc-settlement/account/bill/pay-amount', params)

// 个人中心-我的账单-账单付款|post
export const billPay = params => http.post('account/bill/pay', params)

// 个人中心-我的账单-获取账单详情（导出账单）|get
export const getBilldetail = params => http.get('kjhy-account/h5/me/bill/detail', params)

// 个人中心-我的账单-获取账单信息|get
// export const getBillInfo = params => http.get('newapi/account-bill/index', params)
export const getBillInfo = params => http.get('kjhy-account/account-bill/index', params)

// 下单检验账期是否可以下单|get
// export const checkBillStatus = params => http.get('newapi/account-bill/check-status', params)
export const checkBillStatus = params => http.get('kjhy-account/account-bill/check-status', params)

// ====================================================================== 货物跟踪
const XY_TRACKING_API = 'http://api.fba.top/api/tracking'

// 订单货物跟踪-调用星邮api-支持多个订单号|post
export const getTrackingResult = params => http.post(XY_TRACKING_API, new Array(params))

// ====================================================================== 订单

// 物流轨迹|get
export const getTrackingRecord = params => http.get(`tracking/${params}`)

// 货物跟踪 -- 物流轨迹|get
// export const getLogisticsInfo = params => http.get('newapi/order/home-page/logistics-info', params)
export const getLogisticsInfo = params => http.get('kjsc-transportation/order/home-page/logistics-info', params)

// 货物跟踪 - 查询|post
export const queryLogisticsInfo = params => http.post('kjsc-transportation/h5/home-page/logistics-info/query', params)

// 获取订单列表|get
export const getOrders = params => http.get('order/list', params)

// 订单-取消订单|post
// export const cancelOrder = params => http.post('order/cencelOrderSummary', params)

// 查询所有的fba仓码|get
export const getFbaWarehouseCode = params => http.get('fbawarehouse', params)

// 针对国际干线查询所有国家|get
export const getAllCountries = params => http.get('countrys', params)

// 针对国际干线查询所有省份|get
export const getAllStates = params => http.get(`provinces/${params}`)

// 针对国际干线查询所有城市|get
export const getAllCitys = params => http.get(`citys/${params}`)

// 提交获取订单委托书|post
export const getContract = params => http.post('kjsc-order/h5/me/order/proxies', params)

// 提交订单|post
// export const submitOrder = (params, config) => http.post('order/create', params, config)
export const submitOrder = (params, config) => http.post('kjsc-order/h5/me/order/create', params, config)

// 通过excel上传订单模板|post
export const uploadOrderExcel = (params, config) => http.post('upload/productfile', params, config)

// 校验客户订单号是否已存在|post
// export const checkRefNumber = params => http.post('verification/customerNumber', params)
export const checkRefNumber = params => http.post('kjsc-order/h5/me/order/check-customer-number', params)

// 直客导出订单详情
export const exportOrderDetail = params => http.get('kjsc-order/h5/me/order-export', params, { responseType: 'blob' })

// ===========================================================个人中心-发票管理
// 查询发票管理列表|get
export const getInvoiceList = params => http.get('kjsc-settlement/h5/me/invoice', params)

// 查询发票详情|get
export const getInvoiceDetail = params => http.get('kjsc-settlement/h5/me/invoice/detail', params)

// 查询未开票订单|get
export const noInvoiceOrder = params => http.get('kjsc-settlement/h5/me/uninvoiced-orders', params)

// 开票|post
export const makeInvoice = params => http.post('kjsc-settlement/h5/me/invoice/create', params)

// ====================================================================== 支付
// 通用-获取支付二维码|get
export const getPaycode = params => http.get('pay/getPayCodeUrl', params)

// 获取微信支付结果|get
export const getWechatPayResult = params => http.get('wxcatpay/check/' + params)

// 获取银行列表|get
// export const getBankList = params => http.get('base/banks', params)
export const getBankList = params => http.get('kjsc-basedata/h5/out/get-all-bank', params)

// 获取用户审核状态|get
export const getUserStatus = params => http.get('kjsc-user/center/user/audit/status', params)

// =================================================================== 通知
// 获取放假通知|get
export const getHolidayNotice = params => http.get('sys/notice', params)

// 设置不再提示放假通知|post
export const setHolidayHide = params => http.post('sys/notice/read', params)

// 下单-上传装箱单|post
// export const uploadCargoList = (params, config) => http.post('upload/packinglist', params, config)
export const uploadCargoList = (params, config) => http.post('kjsc-order/h5/zhike/package-list/upload', params, config)

// 下单-获取目的地国家|get
export const getPurposeCountry = params => http.get('kjsc-basedata/shipping/country', params)

// 订单支付-票结|post
export const orderPay = params => http.post('order/pay/online', params)

// 订单支付-新|post
export const newOrderPay = params => http.post('kjsc-settlement/h5/me/order/confirm-pay', params)

// 支付-确认支付
export const confirmPay = params => http.post('kjsc-settlement/h5/me/order/confirm-pay', params)

// 支付二维码-是否支付成功|get
export const onlinePayStatus = (params) => http.get('kjsc-order/h5/me/online/pay-status', params)

// 下单-查询运力商仓库地址|get
// export const getSupplierAddress = params => http.get('supplier/warehouse', params)
// export const getSupplierAddress = params => http.get('sup/warehouse/address', params)
export const getSupplierAddress = params => http.get('kjsc-transportation/h5/me/sup-warehouse/address', params)

// 下单-默认地址-设置|post
export const defaultAddressSet = params => http.put('kjsc-user/www/user/default-address/set', params)

// 下单-送货到仓-列表|post
export const sendToWarehouseList = params => http.post('kjsc-user/www/user/send-to-warehouse/list', params)

// 下单-送货到仓-新增|post
export const sendToWarehouseAdd = params => http.post('kjsc-user/www/user/send-to-warehouse/add', params)

// 下单-送货到仓-编辑|post
export const sendToWarehouseEdit = params => http.post('kjsc-user/www/user/send-to-warehouse/update', params)

// 下单-送货到仓-删除|delete
export const sendToWarehouseDelete = params => http.del('kjsc-user/www/user/send-to-warehouse/delete', params)

// 下单-获取用户地址|get
export const getUserAddress = params => http.get('kjsc-user/user/address', params)

// 下单-新增用户地址|post
export const addUserAddress = params => http.post('kjsc-user/user/address/add', params)

// 下单-修改用户地址|post
export const updateUserAddress = params => http.post('kjsc-user/user/address/update', params)

// 下单-删除用户地址|delete
export const deleteUserAddress = params => http.del('kjsc-user/user/address/delete', params)

// 下单-获取海外仓列表|get
// export const getHWStore = params => http.get('kjhy-supplier/h5/me/sup-foreign-warehouse/list', params)
export const getHWStore = params => http.get('kjsc-basedata/h5/me/oversea-warehouse/list', params)

// 个人中心-我的补贴券列表|get
export const getMyCouponList = params => http.get('kjsc-marketing/coupon/coupons', params)

// 新客有礼弹窗优惠券列表
export const newcomerList = params => http.get('kjsc-marketing/h5/activity/newcomer/list', params)

// 获取天降神券-列表
export const dialogCouponList = params => http.get('kjsc-marketing/www/dialog/coupon/list', params)

// 订单确认页和详情页-获取可用补贴券列表|post
// export const getCoupon = params => http.post('user/coupon/usable', params)
export const getCoupon = params => http.post('kjsc-marketing/h5/me/usable-coupons', params)

// 订单确认页和详情页-获取下一级优惠券|post
export const getNextCoupon = params => http.post('kjsc-marketing/coupon/service/active/secondary-coupons', params)

// 根据高德地图传来的城市id与地图上标点的省市区是否相匹配|post
export const placeOrderAddressMatch = params => http.post('kjsc-solution/h5/me/place/order/address/match', params)

// 订单详情页-绑定补贴券|post
// export const bindCoupon = params => http.post('user/coupon/edit', params)
export const bindCoupon = params => http.post('kjsc-marketing/h5/me/edit-coupon', params)

// 领取补贴券|post
export const getTheCoupon = params => http.post('kjsc-marketing/coupon/user/receive', params)

// 获取带参微信公众号二维码|get
export const getWechatQrcode = params => http.get('wechat/OfficialAccountsQrCode', params)

// 货物查询|post
export const cargoQuery = (params, config) => http.post('kjhy-cargo/cargo-inquire/add', params, config)

// 双12活动 -- 查询|get
export const getDouble12Data = (params, config) => http.get('kjsc-marketing/coupon/get/user-credit', params, config)

// 双12活动 -- 抢券|get
export const getDouble12Coupon = (params, config) => http.get('kjsc-marketing/coupon/get/draw-coupon', params, config)

// 双旦活动 -- 商品详情弹框 | get
export const getGoodDetail = (params, config) => http.get('kjsc-marketing/integrate/goods/query/good-detail', params, config)

// 开工红包页面-用户信息|get
export const getUserDrawCount = (params) => http.get('kjsc-marketing/coupon/h5/me/start-working-active-2020/draw-count', params)

// 开工红包页面-抽奖|post
export const getRedPacket = (params) => http.post('kjsc-marketing/coupon/h5/me/start-working-active-2020/draw', params)

// 报价-查询所有的父级报价列表|post
export const getPlatformRoutes = (params) => http.post('kjhy-supplier/h5/channel/group', params)

// 报价-获取仓库列表
export const getWarehouse = params => http.post('kjsc-transportation/h5/me/user/store/warehouse/list', params)

// 查询报价列表 |post
export const queryOfferList = params => http.post('kjsc-solution/h5/me/channel/list', params)

// 查询小包报价列表 |post
export const queryPacketOfferList = params => http.post('kjhy-supplier/smallpackageplan/list', params)

// 直客个人中心--导出小包下单模板 |post
export const exportPacketData = (params, config) => http.post('kjhy-web/h5/me/order/little-package-excel', params, config)

// 报价-获取报价详情 |get
// export const queryOfferDetail = params => http.get('kjhy-supplier/h5/me/channel/price-range/detail', params)

// 报价-新版获取报价详情 | get
export const queryOfferDetail = params => http.get('kjsc-solution/inquiry/query-transport-scheme-detail', params)

// 报价-报价详情-新样式 | get
export const getNewDetail = params => http.get('kjsc-solution/inquiry/query-transport-scheme-detail/v2', params)

// 报价-获取报价详情 |get
export const queryPacketOfferDetail = params => http.get('kjhy-supplier/smallpackageplan/info', params)

// 报价-获取报价详情-评价列表 |post
export const getCommentList = params => http.post('kjsc-advisor/h5/me/order/comment/list', params)

// 改版-首页-查询用户补贴信息|get
export const getUserSubsidy = (params) => http.get('kjsc-marketing/h5/me/user/subsidy/query', params)

// 改版-首页-首页报价提示列表|post
export const getChargeChangeList = (params) => http.post('kjsc-solution/supplier/charge-change', params)

// 改版-首页-下单历史（渠道推荐）|get
export const getTopSalechannels = (params) => http.get('kjsc-solution/query/top-salechannels', params)

// 改版-下单-获取渠道可走目的国家|get
export const getChannelCountry = (params) => http.get('kjsc-solution/h5/me/channel/country', params)

// 改版-首页-热门运输路线|get
export const getDestinationCountry = (params) => http.get('kjhy-supplier/supplier/destination-country', params)

// 改版-首页-特价路线|get
export const getChannelDiscount = (params) => http.get('kjsc-solution/h5/me/channel/discount/list', params)

// 运营推广H5-获取验证码|get
export const getAppMsgCode = (params) => http.get('kjsc-user/login/valid-code', params)

// 运营推广H5-验证码登录|post
export const appLogin = (params) => http.post('kjsc-user/validcode-login', params)

// 运营推广H5-推广页面-埋点|post
export const pushPoints = (params, config) => http.post('kjhy-account/h5/push/points', params, config)

// 获取用户未付款订单|get
export const getUserUnpayOrders = params => http.get('kjsc-order/query/unpaid-order', params)

// 企业认证|post
export const companyVerify = (params, config) => http.post('kjsc-user/h5/me/user-enterprise/save', params, config)

export const getUserVerifyInfo = params => http.get('kjsc-user/user/elec-signature/getInfo', params)

// 下单-获取海卡渠道仓库|get
export const getHaiKaStore = (params) => http.get('kjhy-supplier/h5/me/channel/haika/fba-code', params)

// 检查520活动弹窗|get
export const get520ActiveStatus = (params) => http.get('kjsc-marketing/h5/me/active/check-active-status', params)

// 获取-是否可以-上门取件|get
export const getCanPickup = params => http.get('kjsc-transportation/h5/out/enable/pickup', params)

// 获取-上门揽件时间段|get
export const getPickupDate = params => http.get('kjsc-transportation/h5/out/pickup-rule', params)

// 修改-上门揽件时间|post
export const updatePickupDate = params => http.post('kjsc-transportation/h5/me/resAppointmentTime/update', params)

// 报价-根据发货城市和收货国家获取运输方式和货物类型|get
export const getChannelTypes = params => http.get('kjsc-solution/h5/me/sale/transport/checkbox/query', params)

// 报价-查询报价列表（精准报价）|post
export const getPreciseOfferList = params => http.post('kjhy-supplier/h5/me/quotation/get-accurate-quotation', params)

// 报价-根据发货城市和收货国家获取货物类型|get
// export const getGoodsType = params => http.get('kjhy-supplier/h5/me/sale/transport/allow-cargo/query', params)

// 根据国家获取省份|get
export const getProvinceByCountryId = params => http.get('kjsc-basedata/h5/out/province/by-country-id', { countryId: '1' })

// 根据省份获取城市|get
export const getCityByProvinceId = params => http.get('kjsc-basedata/h5/out/city/by-province-id', params)

// 根据起运城市目的国家查询（tree)|get
export const getCountryTree = params => http.get('kjsc-solution/h5/out/channel-country/by-city-id', params)

// 通用接口-获取所有国家群组数据
export const getAllCountryTree = params => http.get('kjsc-basedata/h5/me/continent/tree', params)

// 根据IP所在地区|get
export const getCityByIP = params => http.get('kjsc-basedata/h5/out/gaode/province-city', params)

// 查询活动渠道列表|get
export const getChannelActivityList = params => http.get('kjsc-marketing/h5/me/channel-activity/list', params)

// 通过活动名称查询补贴券信息|get
export const getCouponsByActiveName = params => http.get('kjsc-marketing/coupon/h5/me/coupons/by-active-name', params)

// 通过补贴券ID领取补贴券|post
export const getCouponsById = params => http.post('kjsc-marketing/h5/me/active/get-coupon', params)

// 下单-excel模板更新提示|get
export const getOrderTemplateTips = params => http.get('kjsc-order/h5/me/order/check-excel-update', params)

// 电子签-个人信息认证|post
export const personVerify = (params, config) => http.post('kjsc-user/user/elec-signature/orc/identify', params, config)

// 电子签-企业信息认证|post
export const companyVerifyNew = (params, config) => http.post('kjsc-user/user/elec-signature/orc/enterprise', params, config)

// 电子签-获取用户认证状态|get
export const getVerifyStatus = params => http.get('kjsc-user/user/elec-signature/getInfo', params)

// 电子签-生成合同|post
export const makeAgreement = params => http.post('kjhy-account/user/elec-signature/signContract', params)

// 电子签-获取验证码|get
export const getElecSignatureVerifyCode = params => http.get('kjhy-account/user/elec-signature/getVerifyCode', params)

// 电子签-生成合同|post
export const generateElecSignature = params => http.post('kjhy-account/user/elec-signature/generate', params)

// 电子签-签署合同|post
export const comfirmElecSignature = params => http.post('kjhy-account/user/elec-signature/comfirm', params)
// export const comfirmElecSignature = params => http.post('kjhy-account/user/elec-signature/signContract', params)

// 获取用户提现信息记录|get
export const getWithdrawRecord = params => http.get('kjsc-settlement/user/elec-signature/getWithdraw/history', params)

// 电子签-获取用户已填写的信息|get
export const getUserInfo = params => http.get('kjhy-account/user/elec-signature/getRealName/history', params)

// 电子签-是否显示引导框|get
export const getGuideInfo = params => http.get('kjhy-account/user/elec-signature/prompt/setting/get', params)

// 电子签-设置不再显示引导框|post
export const setGuideNeverRemind = params => http.post('kjhy-account/user/elec-signature/prompt/setting/update', params)

// 电子签-获取对公转账企业认证信息|get
export const getCompanyVerifyInfo = params => http.get('kjsc-user/user/elec-signature/H5/me/getEnterPrise', params)

// 电子签-企业对公转账|post
export const companyWithdraw = (params, config) => http.post('kjsc-settlement/withdraw/user/public', params, config)

// 用户引导页面查询|get
export const userWebCheckProcess = params => http.get('kjsc-user/user-web/check-process', params)

// 用户引导页面修改|post
export const userWebUpdateProcess = params => http.post('kjsc-user/user-web/update-process', params)

// 订单详情-获取送货到仓仓库信息|get
export const getOrderStore = params => http.get('kjsc-order/odin/sup-warehouse', params)

// 通过活动名称获取活动信息|post
export const getActiveInfoByName = params => http.post('kjsc-marketing/h5/me/active/info', params)

// 通过活动名称-抽奖|post
export const drawByActiveName = params => http.post('kjsc-marketing/h5/me/draw-by-active-name', params)

// 周年庆（第一年）-领券|get
export const anniversaryOneGetDrawCoupon = params => http.get('kjsc-marketing/coupon/get/draw-coupon', params)

// 提现-获取用户认证信息(个人、企业)|get
export const getUserVerifyData = params => http.get(`kjsc-user/service/getAllAuthInfo/${params}`)

// 个人中心-获取赔付列表|post
export const getReparationList = params => http.post('kjsc-customerservice/h5/me/reparation', params)

// 个人中心-获取赔付列表(NEW)|post
export const getReparationListNew = params => http.post('kjsc-customerservice/h5/me/reparation/new', params)

// 个人中心-获取赔付异常原因下拉|get
export const getReparationReason = params => http.get('kjsc-customerservice/odin/all-reasons', params)

// 物流跟踪-获取赔付信息|get
export const getOrderReparation = params => http.get('kjhy-order/h5/out/order/logistics-pay', params)

// 赔付-上传丢件凭证|post
export const uploadReparationProof = (params, config) => http.post('kjsc-customerservice/h5/me/upload/loss-pay-file', params, config)

// // 订单 - 增加订单评价|post
export const goAssessment = params => http.post('kjsc-advisor/h5/me/order/comment', params)

// // 订单 - 增加订单评价|get
export const checkAssessment = params => http.get('kjsc-advisor/h5/me/order/comment', params)

// 订单-客诉-校验订单所属客诉工单是否处理中|get
export const checkCanComplaint = params => http.get('kjhy-order/h5/me/complaint/check-can-complaint', params)

// 订单-获取投诉记录|post
export const getComplainList = params => http.post('kjhy-order/h5/me/complaint/list', params)

// 订单-获取投诉记录|get
export const getComplainById = params => http.get('kjhy-order/h5/me/complaint/by-order-id', params)

// 订单-新建投诉|post
export const addComplain = params => http.post('kjhy-order/h5/me/complaint', params)

// 订单-客诉-新建投诉-补充|put
export const submitComplaintAppend = params => http.put('kjhy-order/h5/me/complaint/content-append', params)

// 订单-查看投诉处理结果|get
export const getComplainDetail = params => http.get('kjhy-order/h5/me/complaint/by-complaint-id', params)

// 订单-获取投诉类型数据|get
export const getComplainData = params => http.get('kjsc-customerservice/h5/out/complaint/complaint-type-tree', params)

// 订单-获取订单发货地址|get
export const getOrderSendInfo = params => http.get('kjsc-order/h5/me/order/base-info', params)

// 上传文件|post
export const uploadFile = (params, config) => http.post('kjhy-supplier/h5/me/file-upload', params, config)

// 拉新-是否展示红包弹框|get
export const canShowRedBag = params => http.get('kjsc-marketing/h5/me/first-order/red-bag', params)

// 拉新-领取红包|get
export const takeRedBag = params => http.get('kjsc-marketing/h5/me/draw/first-order/red-bag', params)

// 拉新-获取邀请链接|get
export const getInviteLink = params => http.get('kjsc-marketing/h5/me/pull-new/share-link', params)

// 拉新-获取邀请二维码|get
export const getInviteQrCode = params => http.get('kjsc-marketing/h5/me/pull-new/share/qcCode', params)

// 拉新-获取邀请数据信息|get
export const getInviteData = params => http.get('kjsc-marketing/pullNew/invitationInfo', params)

// 拉新-是否是新用户|get
export const getIsNewUser = params => http.get('kjsc-marketing/pullNew/newCustomer', params)

// 拉新-首单奖励|get
export const getFirstOrderInfo = params => http.get('kjsc-marketing/pullNew/firstOrder', params)

// 拉新-邀请用户列表|post
export const getInviteUserList = params => http.post('kjsc-marketing/pullNew/myInvitation', params)

// 拉新-拉新订单列表|post
export const getInviteOrderList = params => http.post('kjsc-marketing/pullNew/myPullNewOrder', params)

// 拉新-是否显示首单提示语|GET
export const canShowTips = params => http.get('kjsc-marketing/h5/me/pull-new/first-order-reminder', params)

// 拉新-获取拉公司下拉|get
export const getCompanyList = params => http.get('kjsc-marketing/pullNew/company-list', params)

// 拉新-补贴金下发|get
export const getReward = params => http.get('kjsc-marketing/pullNew/awardPayment', params)

// 我的订单-批量打印面单|post
export const batchPrintOrder = (params, config) => http.post('kjsc-transportation/h5/me/order/batch-carton-label', params, config)

// 我的订单-批量支付|put
export const batchPayOrder = params => http.put('kjsc-settlement/h5/me/order/batch-pay', params)

// 我的订单-批量支付|post
export const getBatchPayInfo = params => http.post('kjsc-settlement/h5/me/order/batch-pay-info', params)

// 小包下单-上传模板|post
export const uploadTemplateLittleBag = (params, config) => http.post('kjhy-supplier/little-package/calculate/for-create-order', params, config)

// 小包下单-下单|put
export const placeOrderLittleBag = params => http.post('kjhy-web/little-package/order/batch-create', params)

// 加盟商分享订单-订单详情|get
export const getPartnerShareOrder = params => http.get('kjsc-order/partner-share/zhike/order-detail', params)

// 加盟商分享订单-订单详情-确认|post
export const confirmPartnerShareOrder = params => http.post('kjsc-order/partner-share/zhike/confirm-order', params)

// 通用接口-获取所有国家|get
export const getAllCountrys = params => http.get('kjsc-basedata/h5/out/country', params)

// 传统联运-下单|post
export const createOrderTradition = params => http.post('kjhy-order/h5/me/tradition/order/create', params)

// 传统联运-订单详情|get
export const orderTraditionDetail = params => http.get('kjhy-order/h5/me/tradition/order', params)

// 加盟商开店--加盟商店铺列表|post
// export const getJmsStoreList = params => http.post('kjhy-account/h5/me/user/store/search/list', params)
export const getJmsStoreList = params => http.post('kjsc-user/h5/out/user/store/search/list', params)

// 加盟商开店--加盟商店铺信息|get
export const getJmsAllStoreList = params => http.get('kjsc-user/h5/me/user/store/all', params)

// 加盟商开店--加盟商店铺信息|get
// export const getJmsStoreInfoById = params => http.get('kjhy-account/h5/me/user/store/detail/shop-id', params)
export const getJmsStoreInfoById = params => http.get('kjsc-user/h5/out/user/store/detail/shop-id', params)

// 首页-跨去哪获取数据|get
export const getHomeCountryList = params => http.get('kjsc-solution/h5/skip/channel/list', params)

// 企业认证-解析营业执照|post
export const analysisBusinessLicense = params => http.post('kjsc-user/ocr/message', params)

// 公用方法-获取洲和国家|get
export const getContinentAndCountry = params => http.get('kjsc-basedata/h5/me/continent/tree', params)

// 公用方法-文件上传|post
export const uploadFileCommon = (params, config) => http.post('kjsc-basedata/h5/me/upload-file', params, config)

// 公用方法-判断用户是否关注公众号|get
export const isConcernedWechat = (params, config) => http.get('kjsc-user/followWechat', params, config)

// 公用方法-获取带参二维码（关注微信公众号）|get
export const getWechatQRcode = (params, config) => http.get('kjsc-user/h5/me/user/qr-code', params, config)

// 公用方法-获取用户微信名称|get
export const getWechatNickname = (params, config) => http.get('kjsc-user/h5/me/user/wx-nick-name', params, config)

// 公用方法-解绑微信公众号|post
export const unbindWechat = (params, config) => http.post('kjsc-user/h5/me/user/remove-open-id', params, config)

// 公用方法-获取服务商下拉|get
// export const getSupplierOptions = (params, config) => http.get('kjhy-account/user/query-by-user-type', params, config)

// 公用方法-获取服务商下拉|get
export const getSupplierOptions = (params, config) => http.get('kjsc-settlement/payment/query-user-name', params, config)

// 消息中心-获取所有的消息列表 | get
export const getAllMessageList = (params) => http.get('im-api/notify/listNotifyContentHome', params)

// 消息中心-获取消息详情列表 | get
export const getMessageDetailList = (params) => http.get('im-api/notify/listNotifyContentDetail', params)

// 消息中心-全部标记为已读|post
export const messageReadAll = (params) => http.post('im-api/notify/readAll', params)

// 消息中心-已读单条数据|post
export const readOneMessage = (params) => http.post('im-api/notify/read', params)

// 消息中心-获取未读数|get
export const getUnreadCount = (params) => http.get('im-api/notify/totalNoRead', params)

// 财务管理-获取应付列表|post
// export const paymentList = params => http.post('kjhy-finance/payment/list', params)
export const paymentList = params => http.post('kjsc-settlement/payment/customer/payable-list', params)

// 财务管理-获取付款单详情|get
// export const paymentDetails = params => http.get('kjhy-finance/payment/query-by-payment-id', params)
export const paymentDetails = params => http.post('kjsc-settlement/payment/customer/query-payment-info', params)

// 财务管理-获取付款单详情-费用项列表|post
// export const paymentDetailsList = params => http.post('kjhy-finance/payment/detail-list', params)
export const paymentDetailsList = params => http.post('kjsc-settlement/payment/customer/query-payment-order', params)

// 财务管理-付款单付款|post
export const submitPayBill = params => http.post('kjsc-settlement/payment/submit', params)

// 财务管理-付款单下载|get
// export const exportBillFee = (params, config) => http.get('kjhy-finance/payment/export-detail-list', params, config)
export const exportBillFee = (params, config) => http.get('kjsc-settlement/payment/customer/download-payment-details', params, config)

// 财务管理-应付列表-确认付款单
export const confirmBillFee = (params) => http.post('kjsc-settlement/payment/customer/payment-order-confirm', params)

// 短信接收人配置|put
export const setLogisticsReceiver = (params) => http.put('kjsc-transportation/user/add-logistics-notification-number', params)

// 查询账期额度|get
export const getBillDetail = params => http.get('kjsc-settlement/h5/me/customer-account-period/bill-detail', params)

// 参与抽奖活动
export const activeJoin = () => http.get('kjsc-marketing/h5/me/active/join', { activityId: 'kjhy_activity_20210403' })

// 查询剩余抽奖次数
export const remaininglotteryCount = () => http.get('kjsc-marketing/h5/me/active/remaining-lottery-count', { activityId: 'kjhy_activity_20210403' })

// 查询活动特价路线
export const discountrouters = () => http.get('kjhy-supplier/h5/channel/group/discount-routers', { activityId: 'kjhy_activity_20210403' })

// 打开会话接口-用于统计
export const collectOpenSessionNum = (params) => http.get('im-api/im/session/open', params)
// 订单列表-获取服务商下拉|get
export const getServiceList = (params) => http.get('kjsc-user/h5/me/user/select', params)

// 订单列表-费用确认-列表|post
export const costConfirmList = (params) => http.post('kjsc-order/h5/me/costConfirm/list', params)

// 订单列表-费用确认-确认提交|post
export const costConfirmSubmit = (params) => http.post('kjsc-order/h5/me/costConfirm', params)

// // 订单列表-费用确认-确认提交|post
// export const costConfirmSubmit = (params) => http.post('kjhy-order/h5/me/costConfirm', params)

// 账户信息-查看
export const payAccountInfo = params => http.get('kjsc-settlement/payAccount/info', params)
// 账户信息-查看所有
export const payAccountInfoAll = params => http.get('kjsc-settlement/payAccount/info-all', params)
// 账户信息-查看简要信息
export const payAccountSimpleInfo = params => http.get('kjsc-settlement/payAccount/simpleInfo', params)
// 保存账户信息
export const payAccountSave = params => http.post('kjsc-settlement/payAccount/save', params)
// 直客/服务商客户端 我的账期列表查询
export const customerAccountPeriodQuery = params => http.post('kjsc-settlement/period/list', params)

// 直客 账期逾期记录
export const getexpireRecordsList = params => http.get('kjsc-settlement/payment/overdue-record', params)

// 直客 查询下一个账期
export const getNextPeriod = params => http.post('kjsc-settlement/period/next-period', params)

// 获取notify的ws服务重连时长
export const getNotifyServeTime = (params) => http.get('im-api/notify/config/heartbeat', params)

// 获取城市和目的地
export const cityInfo = (params) => http.post('kjsc-basedata/h5/out/country/city/info', params)

// 获取认证Url(去法大大认证)
export const goCertByFDD = (params) => http.get('kjsc-user/user/elec-signature/contractAuthUrl', params)

// 获取合同(去法大大签约)
export const signContractByFDD = params => http.get('kjsc-user/user/elec-signature/contractSign', params)

// 补贴中心-获取列表|get
export const getSubsidyList = params => http.get('api/kjsc-marketing/activity/task/list', params)

// 补贴中心-获取历史列表|get
export const getSubsidyHistoryList = params => http.get('api/kjsc-marketing/activity/task/historyList', params)

// 补贴中心-获取已完成的任务卡|get
export const getSubsidyReward = params => http.get('api/kjsc-marketing/activity/task/doneList', params)

// 补贴中心-获取已邀请的用户|get
export const getInviteUser = params => http.get('api/kjsc-marketing/activity/task/pullNewList', params)

// 补贴中心-获取用户分享链接和二维码|get
export const getInviteCode = params => http.get('kjsc-marketing/h5/me/pull-new/share', params)

// 一口价-根据商品名模糊查询
export const searchProduct = params => http.get('kjsc-solution/h5/product/search', params)

// 一口价-查询全部路线列表
export const searchGroupList = params => http.post('kjsc-solution/h5/channel/group', params)

// 一口价-未搜索到结果上报
export const reportNotFound = params => http.post('kjsc-solution/h5/product/search/resultless', params)

// 下单 -  户未认证状态下判断用户是否下单超过10单
export const getIsoverCount = params => http.post('kjsc-order/h5/me/order/limit/condition-flag', params)

// 直客端--直客授权列表|post
export const authorizationList = params => http.post('kjhy-openapi/h5/auth/list', params)

// 直客端--直客授权列表-启用|post
export const authorizationEnable = params => http.post('kjhy-openapi/h5/auth/confirm', params)

// 直客端--直客授权列表-禁用|post
export const authorizationDisable = params => http.post('kjhy-openapi/h5/auth/disable', params)

// 报价页-查询报价列表
export const getRouteList = params => http.post('kjsc-solution/h5/me/transportScheme/list', params)

// 首页\报价页-获取关键词联想搜索
export const getKeywordList = params => http.get('kjsc-solution/inquiry/home-associate-keyword', params)

// 报价页-查询报价列表-新
export const getNewRouteList = params => http.post('kjsc-solution/inquiry/query-home-scheme-list', params)

// 报价页-查询报价列表-新-一级页面
export const channelsListV2 = params => http.post('kjsc-solution/www/inquiry/channels-v2', params, { abortPrev: true })

// 报价页-查询报价列表-新-一级页面
export const channelsList = params => http.post('kjsc-solution/www/inquiry/channels', params, { abortPrev: true })

// 报价页-查询报价列表-新-二级页面列表
// export const schemeListbylibidV2 = params => http.post('kjsc-solution/www/inquiry/channels/lib-info-and-scheme-v2', params, { abortPrev: true })
export const schemeListbylibidV3 = params => http.post('kjsc-solution/www/inquiry/channels/lib-info-and-scheme-v3', params, { abortPrev: true })

// 报价页-查询报价列表-价格趋势
export const getSchemePricesStatistics = params => http.post('kjsc-solution/www/inquiry/channels/get-pricesStatistics-v3', params, { abortPrev: true })

// 报价页-查询报价列表-新-二级页面列表
export const schemeListbylibid = params => http.post('kjsc-solution/www/inquiry/channels/lib-info-and-scheme', params)

// 报价页-查询报价列表
export const getRouteListNew = params => http.post('kjsc-solution/inquiry/query-transport-scheme', params)

// 报价页-获取热门线路名
export const getHotRoute = params => http.get('kjsc-solution/h5/me/popular/search', params)

// 首页-最近下单|get
export const getRecentOrderInfo = params => http.get('kjsc-order/h5/out/latest/order/channel', params)

// 首页-特价线路列表|get
export const getSpecialChannels = params => http.get('kjsc-solution/h5/out/specail-price', params)

// 首页-特价线路-用户下单滚动轮播|get
export const getOrderUserSwiper = params => http.get('kjsc-solution/h5/out/specail-price/rolling-list', params)

// 通过城市id 目的地code查询城市和目的国的全部信息
export const getCityCountry = (params, config) => http.post('kjsc-basedata/h5/out/country/city/info', params, config)

// 获取最近的装箱单信息
export const getLatestCarton = params => http.get('kjsc-order/h5/order/carton', params)

// 服务商入驻|post
export const submitVipJoinInfo = params => http.post('kjsc-serviceprovider/service-provider/info-add', params)

// 服务商入驻-获取验证码|get
export const getVipJoinMsgCode = params => http.get('kjsc-serviceprovider/service-provider/sms-code', params)

// 双节活动上报数据
export const activeShareCount = params => http.post('kjsc-marketing/active-share/count', params)

// 获取817活动列表数据
export const get817ActiveList = params => http.get('kjsc-marketing/h5/me/channel-activity/for-817', params)

// 11月活动-获取列表|post
export const getActiveNovList = params => http.post('kjhy-price/activity-config/customer-list', params, { abortPrev: true })

// 11月活动-查询补贴券是否领取|get
export const isGetActiveNovCoupon = params => http.get('kjsc-marketing/coupon/coupons', params)

// 11月活动-领取补贴券|post
export const getActiveNovCoupon = params => http.post('kjsc-marketing/coupon/receive', params)

// 获取地区信息
export const geoDistrict = params => http.get('kjsc-basedata/global-city/geo-district', params)

// 详细地址联想
export const addressCompletion = params => http.post('kjsc-basedata/global-city/address-completion', params)

// 增加未入驻的服务商评价 - 直客
export const reputationAdd = params => http.post('kjsc-advisor/h5/me/reputation-comment/add', params)
// 服务商口碑查询详情--直客
export const reputationDetail = params => http.get('kjsc-advisor/h5/me/service-provider-reputation/detail', params)
// 服务商口碑查询详情-方案详情-新-直客
export const getNewReputationDetail = params => http.get('kjsc-advisor/h5/me/service-provider-reputation/detail/v2', params)
// 直客端 - 服务商口碑评价列表
export const shopCommentList = params => http.post('kjsc-advisor/h5/reputation-comment/list', params)
// 直客 - 提交修改服务商资料申请
export const modifyAdd = params => http.post('kjsc-serviceprovider/service-provider-modify/add', params)
// 直客端 - 评价列表 (交易)
export const orderCommentList = params => http.post('kjsc-advisor/h5/me/order/comment/list', params)
// 获取店铺的FBA和小包默认发货城市及目的国家
export const cityCountry = params => http.get('kjsc-solution/h5/shop/city-country/query', params)
// 报价页-查询报价列表(不需要登录)线路推荐
export const getNotRouteList = params => http.post('kjsc-solution/h5/transportScheme/list ', params)
// 省市区三级地址|get
export const getAreaData = params => http.get('kjsc-basedata/sys-area/get-sys-areas', params, { cachekey: true })

// 会员权益-获取当前会员等级以及距离下个等级的差值|get
export const getUserLevelInfo = params => http.get('kjsc-marketing/user-vip/get-user-vip', params)

// 会员权益-我的权益发放状态|get
export const getMyRights = params => http.get('kjsc-marketing/user-vip/user-rights-send-status', params)

// 会员权益-节日关怀领取列表|post
export const getHolidayCareList = params => http.post('kjsc-marketing/user-gift/get-user-gift-list', params)

// 会员权益-节日关怀-用户地址|get
export const getHolidayCareAddress = params => http.get('kjsc-marketing/user-gift/get-user-gift-address', params)

// 会员权益-节日关怀-用户地址编辑|post
export const updateHolidayCareAddress = params => http.post('kjsc-marketing/user-gift/modify-user-gift-address', params)

// 获取询价内容
export const priceDemand = (id, params) => http.get(`kjhy-cs-bff/im/price/demand/${id}`, params)

// 海外仓信息
export const overseaWarehouseAccountInfo = (params) => http.get('kjsc-transportation/h5/me/oversea-warehouse-account/info', params)

// ====================================================================== 首页改版

// 获取用户搜索及浏览记录
export const getHistorys = params => http.get('kjsc-solution/h5/get-user-quote-search-browse-record', params)

// 清空浏览记录
export const removeHistorys = params => http.get('kjsc-solution/h5/del-user-quote-search-record', params)

// 直客官网-运营模块-活动模块类别
export const getMarketTypes = params => http.get('kjsc-solution/h5/out/line_market-type', params)

// 直客官网-运营模块-报价
export const getMarketChannels = params => http.get('kjsc-solution/h5/out/line_market-channel-price', params)

// 获取快照|get
export const getSnapshot = (params) => http.get('kjsc-order/h5/me/order/order-snapshot', params)

// 判断计费快照是否生成
export const isHasSnapshot = (params) => http.get('kjsc-order/h5/me/order/order-has-snapshot', params)

// 渠道补贴券-查询渠道是否有效|post
export const isUserfulChannel = (params) => http.post('kjsc-marketing/h5/channel-promotion/status', params)

// 渠道补贴券-领取补贴券
export const takeChannelCoupon = (params) => http.post('kjsc-marketing/h5/channel-promotion/createcoupon', params)

// ===========================================================活动列表
// 活动列表-活动信息|get
export const activityInfoById = (params, config) => http.get('kjsc-marketing/h5/activity/subsidy/info', params, config)

// 活动列表-查询活动的完成值|get
export const activityCompletedInfo = (params, config) => http.get('kjsc-marketing/h5/activity/subsidy/completed/list', params, config)

// 活动列表-正在进行中的活动|get
export const ongoingActivityList = (params, config) => http.get('kjsc-marketing/h5/activity/subsidy/ongoing/list', params, config)

// 活动列表-获取活动支持的起运地和目的国|get
export const activityCityAndCountry = params => http.get('kjsc-marketing/h5/activity/subsidy/supported/city-and-country', params)

// 活动列表-获取活动专区|get
export const activityAreaAvaileList = params => http.get('kjsc-marketing/activity/activity-area/availe-list', params)

// 活动列表-列表|get
export const transportActiveList = params => http.post('kjsc-solution/www/inquiry/active/transport/list', params)

// ===========================================================2022开门红
// 2022开门红-活动信息|get
export const goodStartSummary = params => http.get('kjsc-marketing/good-start/summary', params)

// 2022开门红-自己兑换的兑换商品|get
export const goodStartOwnGoods = params => http.get('kjsc-marketing/good-start/goods/exchange', params)

// 2022开门红-兑换商品|post
export const goodStartGetCoupon = params => http.post('kjsc-marketing/good-start/getcoupon', params)

// 2022开门红-兑换商品|post
export const goodStartExchangeGoods = params => http.post('kjsc-marketing/good-start/goods/exchange', params)

// 2022开门红-获奖列表|get
export const goodstartLotteryWinner = params => http.get('kjsc-marketing/good-start/lottery/winner', params)
// 冰墩墩--点击抽奖
export const lotteryClick = params => http.get('kjsc-marketing/good-start/lottery/click', params)

// ===========================================================2022开门红
// 心想事成活动-新客有礼补贴券接口|get
export const wishcometrueNewcomerList = params => http.get('kjsc-marketing/h5/wishcometrue/newcomer/list', params)

// 心想事成活动-[新]喜若狂线路 - 补贴金列表|get
export const wishcometrueSubsidyCouponList = params => http.get('kjsc-marketing/h5/wishcometrue/subsidy/list', params)

// 心想事成活动-[新]喜若狂线路列表|get
export const wishcometrueSubsidyPlanList = params => http.get('kjsc-marketing/h5/wishcometrue/subsidy/plan-list', params)

// 心想事成活动-全心全意完成值接口|get
export const wishcometrueOrderList = params => http.get('kjsc-marketing/h5/wishcometrue/order/list', params)

// 心想事成活动-全心全意线路列表|get
export const wishcometrueOrderPlanList = params => http.get('kjsc-marketing/h5/wishcometrue/order/plan-list', params)

// ===========仓位维护需求==============================
// 获取渠道船期仓位列表 | GET
export const getChannelShipSpace = params => http.get('kjsc-solution/inquiry/query-scheme-stores', params)

// ===========================================================对比
// 方案库对比 | get
export const comparedChannelsList = params => http.get('kjsc-solution/www/compared/list', params, { abortPrev: true })

// 线路对比-侧边栏 | get
export const comparedSchemeSildeList = params => http.get('kjsc-solution/www/compared/querylist', params, { abortPrev: true })

// 线路对比 | get
export const comparedSchemeList = params => http.get('kjsc-solution/www/compared/linelist', params, { abortPrev: true })

// 对比-新增 | post
export const comparedAdd = params => http.post('kjsc-solution/www/compared/insert', params)

// 对比-排序 | post
export const comparedSort = params => http.post('kjsc-solution/www/compared/sort', params)

// 对比-删除 | del
export const comparedDelete = params => http.del('kjsc-solution/www/compared/delete', params)

// 对比-清空 | del
export const comparedClear = params => http.del('kjsc-solution/www/compared/deleteall', params)

// ===========================================================积分商城

// 积分商城-获取商品列表|post
export const getIntegralGoods = params => http.post('kjsc-marketing/integrationorder/integration-orders', params)

// 积分商城-兑换商品|post
export const integralRedeem = params => http.post('kjsc-marketing/integrationorder/integration-exchange', params)

// 积分商城-获取用户兑换记录月份|get
export const getIntegralRedeemMonth = params => http.get('kjsc-marketing/integrate/get-integration-record-month', params)

// 积分商城-获取用户兑换记录|get
export const getIntegralRedeemRecord = params => http.get('kjsc-marketing/integrate/get-integration-record', params)

// 积分商城-获取2021年12月抽奖活动积分|get
export const get12Integral = params => http.get('kjsc-marketing/integrate/get-dec-twelve-integration', params)

// SaaS宣传页-申请-用户是否已填写资料
export const hasFinishInfo = params => http.get('kjsc-user/h5/me/saas/isApply', params)

// SaaS-提交申请信息
export const applyConfirm = params => http.post('kjsc-user/h5/me/saas/apply', params)

// SaaS-邀请码开通功能
export const codeConfirm = params => http.post('kjsc-user/h5/me/saas/open' + params)

// ===========================================================长账期

// 长账期-检验服务商账期时长是否小于客户时长
// export const checkCouldUseAccountPeriod = params => http.get('kjsc-settlement/h5/me/period/couldUseAccountPeriod', params)

// 长账期-付款判断是否可用账期结算
export const checkCouldUseAccountPeriod = params => http.get('kjsc-settlement//period/h5/me/isCanUsePeriod', params)

// 长账期-检验客户类型
export const queryPeriodType = params => http.get('kjsc-settlement/period/h5/me/query-period-type', params)

// 查询服务商标签名称列表
export const queryServiceTargetList = params => http.get('kjsc-solution/channel-label/all-sale-scheme-label-names', params)

// 新增是否展示banner逻辑
export const isShowBanner = params => http.get('kjsc-solution/h5/out/banner-open', params)

// 首页-搜索-方案条数查询
export const getSearchPlan = params => http.post('kjsc-solution/www/inquiry/channels/tip', params)

// =========================================================== 整柜
// 获取启运港和目的港基础数据|get
export const getContainerPort = params => http.get('kjsc-solution/client/container/scheme/query/initial-data', params, { cachekey: false })

// 启运地和目的国模糊查询|get
export const searchCityOrCountry = params => http.get('kjsc-solution/container/harbor/dim-query/site', params)

// 港口模糊查询|get
export const searchPort = params => http.get('kjsc-solution/container/harbor/dim-query/harbor', params)

// 查询整柜方案|post
export const getContainerScheme = params => http.post('kjsc-solution/client/container/scheme/list-to-client', params)

// 生成整柜订单委托书|post
export const createContainerBook = params => http.post('kjsc-container-order/container-order/create-proxies-url', params)

// 整柜下单|post
export const submitContainerOrder = params => http.post('kjsc-container-order/container-order/create-container-order', params)

// 获取整柜订单|post
export const getContainerOrder = params => http.post('kjsc-container-order/container-order/zk/query-container-orders', params)

// 取消整柜订单|post
export const cancelContainerOrder = params => http.post('kjsc-container-order/container-order/zk/cancel-container-order', params)

// 获取整柜订单费用明细|get
export const getContainerOrderFee = params => http.get('kjsc-container-order/container-order-fee/zk/get-fee-details', params)

// 获取整柜订单详情|get
export const getContainerOrderDetail = params => http.get('kjsc-container-order/container-order/zk/container-orders-detail', params)

// 生成修改整柜订单委托书|post
export const createEditContainerBook = params => http.post('kjsc-container-order/container-order/zk/update-container-orders-detail', params)

// 修改整柜订单|post
export const editContainerOrder = params => http.post('kjsc-container-order/container-order/zk/confirm-update-container-orders-detail', params)

// 获取整柜拖车地址省市区三级地址|get
export const getContainerTruckAddr = params => http.get('kjsc-basedata/sys-area/get-sys-areas-and-id', params)

// =========================================================== 国际小包

// 查询小包订单列表|post
export const getPackets = params => http.post('packet-order-customer-bff/h5/me/packetOrder/list', params)

// 查询小包订单详情|get
export const getPacketDetails = params => http.get('packet-order-customer-bff/h5/me/packetOrder/detail', params)

// 批量下载小包订单面单|post
export const getPacketCartonLabel = (params, config) => http.post('packet-order-customer-bff/h5/out/packetOrder/getCartonLabel', params, config)

// 取消小包订单（单个取消）|post
export const cancelPacketOrder = params => http.post('packet-order-customer-bff/h5/me/packetOrder/cancel', params)

// 批量支付小包订单|post
export const batchpayPackets = params => http.post('kjsc-settlement/h5/me/order/confirm-pay', params)

// 批量导出小包订单|post
export const batchExportPackets = (params, config) => http.post('packet-order-customer-bff/h5/out/packetOrder/export', params, config)

// 获取订单状态|get
export const getPacketOrderStatus = params => http.get('packet-order-customer-bff/h5/me/packetOrder/orderStatus/items', params)

// 获取支付状态|get
export const getPacketPayStatus = params => http.get('packet-order-customer-bff/h5/me/packetOrder/orderPayStatus/items', params)

// 小包-获取货物属性
export const getGoodsProt = params => http.get('packet-order-customer-bff/h5/me/goodsList', params)

// 小包-起运城市下拉列表
export const getPacketDepartureCities = params => http.get('packet-order-customer-bff/h5/me/fromCityNameList', params)

// 小包-获取线路列表
export const getPlanList = params => http.post('packet-order-customer-bff/h5/me/inquiry', params)

// 小包-获取线路价格详情
export const getPacketPriceDetails = params => http.post('packet-order-customer-bff/h5/me/channel-route-detail', params)

// 小包-获取自营线路价格详情
export const getPacketSelfPriceDetails = params => http.post('packet-order-customer-bff/h5/me/quotation-detail', params)

// 小包-提交试价
export const submitSmallOrderPrice = params => http.post('packet-order-customer-bff/h5/me/carriageCalculate', params)

// 小包-提交订单
export const submitSmallOrder = params => http.post('packet-order-customer-bff/h5/me/carriageCreate', params)

// CRM-行为通知
export const crmNotify = params => http.post('kjsc-user/user/operate/notify', params)

// 获取国际小包所有目的国|get
export const getSmallCountry = params => http.get('kjsc-basedata/country/query/detail-all', params)

// 模糊查询国际小包目的国|get
export const searchSmallCountry = params => http.get('kjsc-basedata/country/query/detail-dim', params)

// 国际小包-ERP失败列表
export const getPacketErrorList = (params) => http.post('packet-order-customer-bff/erp/packetOrderStatusRecords', params)

// 国际小包-物流轨迹查询
export const getPacketLogist = (params) => http.post('packet-order-customer-bff/h5/me/packetOrder/logistics/trace', params)

// 方案详情-获取最好的一条评价
export const getRouteBestComment = params => http.get('kjsc-advisor/h5/common/order/bestcomment', params)

// 获取新版标签列表
export const getLabelSelect = params => http.get('kjsc-solution/www/inquiry/labelSelect', params)

// 全网询价方案库下拉
export const getChannelSelect = params => http.get('kjsc-solution/odin/lib/channel/select-list', params)

// 下单获取线路信息(目前后端只加了免费上门取货配置的起收重信息，后面可以直接扩展这个接口)
export const getChannelMoreInfo = params => http.get('kjsc-solution/channel-label/door-take-label-config/charge-weight', params)

// 签约文件下载
export const getSignFile = params => http.get('kjsc-serviceprovider/service/electronicSignature/byUserId', params)

// 首页专区列表
export const getHomePageList = params => http.get('kjsc-solution/line-market/activity/home-page-query', params)

// 首页专区-目的国列表
export const getOriginCityList = params => http.get('kjsc-serviceprovider/city-list', params)

// 专区-线路列表
export const getLineList = params => http.post('kjsc-solution/line-market/activity/home-page-line-query', params)

// 获取用户的支付主体信息
export const getUserPaySubject = params => http.get('kjsc-settlement/user/bind-mch/by-userid', params)

// 获取IM在线状态
export const getImStatus = params => http.post('im-api/get-service-provider-online-status', params)

// =========================================================== 送货到仓
// 通过快递单号查询快递公司
export const queryExpressCompanies = params => http.get('kjsc-transportation/h5/me/kuaidi-company/by-num', params)

// 通过订单号查询快递单号列表
export const queryWarehouseNumbers = params => http.get('kjsc-transportation/h5/me/warehouse-no', params)

// 直客保存快递单号信息
export const addWarehouseNumber = params => http.post('kjsc-transportation/h5/me/warehouse-no', params)

// 直客修改快递单号信息
export const upWarehouseNumber = params => http.put('kjsc-transportation/h5/me/warehouse-no', params)

// 通过快递单号查询轨迹
export const queryWarehouseNumberTrack = recordId => http.get(`kjsc-transportation/h5/me/warehouse-no-track-log/${recordId}`)

// 删除订单的快递单号
export const delWarehouseNumber = recordId => http.del(`kjsc-transportation/h5/me/warehouse-no/${recordId}`)

// 方案库页-获取方案库类型
export const getChannelTypeList = params => http.post('kjsc-solution/www/index/channel-types', params)

// 方案库页-获取方案库列表
export const getChannelLibList = params => http.post('kjsc-solution/www/index/channel-libs', params)

// =========================================================== TWL 改版
// 首页-查询特价线路
export const getSpecialRoutes = params => http.get('kjsc-solution/www/index/special-list', params)

// 方案列表页-查询方案列表
export const queryChannelList = params => http.post('kjsc-solution/www/index/channel-list-v5', params)

// 方案列表页-查询线路标签下拉
export const channelLabelOptions = params => http.get('kjsc-solution/channel-label/select', params)

// 创建询价需求
export const createHallInquiry = params => http.post('kjsc-solution/www/inquiry/hall/create', params)

// 自定制发货查价
export const aiInquiry = params => http.post('kjsc-solution/www/customer/made/get-fast-scheme', params)

// 自定制发货-货物类型
export const aiGoodsTypes = params => http.get('kjsc-solution/www/ai/inquiry/goods-type', params)

// 自定制发货查价列表
export const customQuoteList = params => http.post('kjsc-solution/www/ai/inquiry/index', params)

// 自定制预下单
export const createAiOrder = params => http.post('kjsc-order/www/estimate-order/create', params)

// 自定制补充订单
export const improveAiOrder = params => http.post('kjsc-order/www/order/create', params)

// 自定制订单-列表
export const aiOrderList = params => http.post('kjsc-order/www/estimate-order/query', params)

// 自定制订单-详情
export const aiOrderDetails = params => http.get('kjsc-order/www/estimate-order/details', params)

// 自定制订单-取消
export const cancelAiOrder = params => http.post('kjsc-order/www/order/cancel', params)
