import mutations from './mutations'
import actions from './actions'

const state = {
  goodsTypeList: [],
  expectedTotalPriceList: [
    {
      label: '100以内',
      min: 0,
      max: 100,
    },
    {
      label: '100-150',
      min: 100,
      max: 150,
    },
    {
      label: '150-200',
      min: 150,
      max: 200,
    },
    {
      label: '250-300',
      min: 250,
      max: 300,
    },
    {
      label: '300-350',
      min: 300,
      max: 350,
    },
  ],
  signingTimeList: [
    {
      label: '7天内',
      min: 0,
      max: 7,
    },
    {
      label: '5-10天',
      min: 5,
      max: 10,
    },
    {
      label: '10-15天',
      min: 10,
      max: 15,
    },
    {
      label: '15-20天',
      min: 15,
      max: 20,
    },
    {
      label: '20-25天',
      min: 20,
      max: 25,
    },
  ],
  channelTypeList: [
    {
      value: 'AIR',
      label: '空运',
    },
    {
      value: 'SHIPPING',
      label: '海运',
    },
    {
      value: 'RAILWAY',
      label: '铁运',
    },
    {
      value: 'EXPRESS',
      label: '快递',
    },
    {
      value: 'HAIKA',
      label: '海卡',
    },
    {
      value: 'TRUCK',
      label: '卡航',
    },
  ],

  // 平台保障
  platformLabelList: [
    {
      label: '假一赔三',
      value: '假一赔三',
    },
    {
      label: '丢损赔',
      value: '丢损赔',
    },
    {
      label: '延时赔',
      value: '延时赔',
    },
    {
      label: '舱位保',
      value: '舱位保',
    },
  ],

  // 计泡系数
  bubbleCoefficientList: [
    {
      value: '3300',
      name: '3300',
    },
    {
      value: '4000',
      name: '4000',
    },
    {
      value: '5000',
      name: '5000',
    },
    {
      value: '6000',
      name: '6000',
    },
    {
      value: '7000',
      name: '7000',
    },
    {
      value: '8000',
      name: '8000',
    },
  ],

  // 分泡方式
  bubbleTypeList: [
    {
      value: 'HALF_BUBBLE',
      name: '半泡',
    },
    {
      value: 'THREE_TENTHS_BUBBLE',
      name: '30%泡',
    },
    {
      value: 'ONE_THIRD_BUBBLE',
      name: '1/3泡',
    },
    {
      value: 'NOT_BUBBLE',
      name: '不分泡',
    },
  ],
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
