import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import user from './modules/user'
import order from './modules/order'
import favorites from './modules/favorites'
import compare from './modules/compare'
import ai from './modules/ai'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    app,
    user,
    order,
    favorites,
    compare,
    ai,
  }
})

export default store
