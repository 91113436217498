import dayjs from 'dayjs'
import NP from 'number-precision'
import { isDate, isNumber, fixDate, formatDate } from '@/plugins/wjtools/util'

export const filters = {
  // 文字超出截断
  wordSlice(word, length) {
    if (!word || typeof value !== 'string') return word
    return word.length > length ? word.slice(0, length - 1) + '...' : word
  },

  // 分转元
  fen2yuan(value) {
    return !isNaN(value) ? (value / 100).toFixed(2) : value
  },

  // 去掉货币符号
  delyuan(value) {
    return typeof value === 'string' ? Number(value.replace('￥', '')) : 0
  },

  // 格式化数字
  fmtNumber(v, len = 0) {
    if (!isNumber(v)) return v ?? '--'
    return NP.round(v, len)
  },

  // 格式化银行卡号
  fmtBankcard(value) {
    if (value && typeof value === 'string') {
      value = value.slice(0, 4) + ' *** *** ' + value.slice(-4)
    }
    return value
  },

  /**
   * 功能：将时间戳按照给定的时间/日期格式进行处理
   * @param {Number} date 时间戳
   * @param {String} fmt 时间格式 'YYYY-MM-DD HH:mm:ss'
   * @returns {String} 规范后的 时间/日期 字符串
   */
  // fmtDate: (time, fmt) => formatDate(time, fmt),
  fmtDate(time, fmt = 'YYYY-MM-DD HH:mm:ss') {
    if (!time) return '--'
    time = fixDate(time) // 格式化时间为苹果系统可兼容的格式
    return dayjs(time).format(fmt)
  },

  // 时间戳格式化为时分秒（00:00:00）
  sec2time(sec, fmt = '00:00') {
    if (!sec || sec <= 0) return fmt
    // sec = Math.floor(sec)
    let h = String(Math.floor(sec / 3600)).padStart(2, '0')
    let m = String(Math.floor(sec / 60) % 60).padStart(2, '0')
    let s = String(sec % 60).padStart(2, '0')
    if (h > 0) {
      return `${h}:${m}:${s}`
    } else {
      return `${m}:${s}`
    }
  },

  // 时分秒（00:00:00）转为时间戳
  time2sec(time) {
    if (!time) return ''
    let h = time.split(':')[0]
    let m = time.split(':')[1]
    let s = time.split(':')[2]
    return Number(h * 3600) + Number(m * 60) + Number(s)
  },

  dateFormat(date) {
    if (!date) return '--'
    if (!isDate(date)) {
      date = new Date(date)
    }
    return date.getFullYear() + '年' + (date.getMonth() + 1) + '月' + date.getDate()
  },

  // 时间对象数组转字符串
  formatDates(dates, separator = '-') {
    if (!Array.isArray(dates)) return dates
    return dates.filter(date => date).map(date => formatDate(date)).join(separator)
  },

  daterangeFormat(startDate, endDate) {
    if (startDate && endDate) {
      return filters.dateFormat(startDate) + '-' + filters.dateFormat(endDate)
    } else if (startDate) {
      return filters.dateFormat(startDate)
    } else if (endDate) {
      return filters.dateFormat(endDate)
    } else {
      return '--'
    }
  },

  wayName(way) {
    switch (Number(way)) {
      case 1:
        return '门到门'
      case 2:
        return '门到港'
      case 3:
        return '港到门'
      case 4:
        return '港到港'
      case 5:
        return '拖车装货'
      case 6:
        return '拖车送货'
    }
  },

  // 0:20GP 1:20GQ 2:40GP 3:40HQ 4:2*20GP 5:2*20HQ
  boxTypeName(type) {
    switch (Number(type)) {
      case 0:
        return '20GP'
      case 2:
        return '40GP'
      case 3:
        return '40HQ'
    }
  },

  getCity(addrsss) {
    if (!addrsss || typeof addrsss !== 'string') return addrsss
    return addrsss.replace(/^(?:.+(?:省|自治区))?(.+?)(?:市|特别行政区|区|县|自治县).*/, '$1') || addrsss.replace(/^(.+?)(?:省|自治区).*/, '$1')
  },

  // 合并详细地址
  addrFormat(addrs, address) {
    if (!addrs || !addrs.length) return '--'
    return [...addrs.map(item => item.name), address].join('')
  },

  // 根据地址类型提示文案
  addrNotice(type) {
    switch (type) {
      case '1': // 没有海运
        return '该地区不支持海运，请选择其他运输方式'
      case '2': // 禁区
      case '3': // 三级禁区
      case '4': // 四级禁区
        return '该地区为禁区，无法承运'
      default:
        return ''
    }
  },

  // 小包计费方式
  packetCalculateType(v) {
    switch (String(v)) {
      case '0':
      case 'fake_data':
        return '实重'
      case '1':
        return '体积重'
      case '2':
        return 'CBM'
      case '3':
        return '体积重和实重取大'
      default:
        return '--'
    }
  },

  // 数字千分位分割
  addThousands(num) {
    num = String(num)
    if (num.includes('.')) {
      return num.replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => $1 + ',')
    } else {
      return num.replace(/\d{1,3}(?=(\d{3})+$)/g, '$&,')
    }
  },

  // 截取文件名称
  subFileName(url) {
    return (url && typeof url === 'string') ? url.substr(url.lastIndexOf('/') + 1) : '--'
  }
}

export default Vue => {
  Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
  })
}
