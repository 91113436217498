// 有页眉页脚的页面路由
export default [
  {
    path: '/',
    component: () => import('@/views/layout'),
    children: [
      {
        path: '',
        name: 'index-new',
        component: () => import('@/views/home/home-redesign'),
        meta: {
          title: '佳成国际_首页',
        },
      },
      {
        path: '/quoted-price-new',
        alias: '/quoted-price',
        name: 'quoted-price-new',
        component: () => import('@/views/home/quoted-price/index'),
        meta: {
          title: '佳成国际-方案列表',
        },
      },
      {
        path: '/quoted-price-ai',
        name: 'quoted-price-ai',
        component: () => import('@/views/home/quoted-price/index-ai'),
        meta: {
          title: '佳成国际-自定义方案',
        },
      },
      {
        path: '/offer-detail',
        name: 'offer-detail',
        component: () => import('@/views/home/quoted-price/offer-detail'),
        meta: {
          needAuth: true,
          title: '佳成国际-方案详情',
        },
      },
      {
        path: '/place-order',
        name: 'place-order',
        component: () => import('@/views/home/place-order/place-order'),
        meta: {
          // keepAlive: true,
          needAuth: true,
          title: '佳成国际-立即下单',
        },
      },
      {
        path: '/routeoff-tips',
        name: 'routeoff-tips',
        component: () => import('@/views/home/place-order/routeOff-tips'),
        meta: {
          needAuth: true,
          title: '线路已下架',
        },
      },
      {
        path: '/success',
        name: 'success',
        component: () => import('@/views/home/place-order/place-order-success'),
        meta: {
          needAuth: true,
          title: '佳成国际-下单成功',
        },
      },
      {
        path: '/ai-success',
        name: 'ai-success',
        component: () => import('@/views/home/place-order/ai-place-order-success'),
        meta: {
          needAuth: true,
          title: '佳成国际-下单成功',
        },
      },
      {
        path: '/track-order',
        name: 'track-order',
        component: () => import('@/views/home/track-order/index'),
        meta: {
          title: '佳成国际-货物跟踪',
        },
      },
      {
        path: '/platform',
        name: 'platform',
        component: () => import('@/views/home/platform'),
        meta: {
          title: '佳成国际-关于我们',
          menu: 'about',
        },
      },
      {
        path: '/order-detail',
        name: 'order-detail',
        component: () => import('@/views/home/order-detail'),
        meta: {
          title: '佳成国际-订单详情',
        },
      },
      {
        path: '/snapshot-order',
        name: 'snapshot-order',
        component: () => import('@/views/my-center/order/snapshot-tpl'),
        meta: {
          needReload: true,
          title: '佳成国际-下单快照',
        },
      },
      {
        path: '/snapshot-fee',
        name: 'snapshot-fee',
        component: () =>
          import('@/views/my-center/order/snapshot-tpl/fee-snapshot'),
        meta: {
          needReload: true,
          title: '佳成国际-下单快照',
        },
      },
      {
        path: '/service-guarantee',
        name: 'service-guarantee',
        component: () => import('@/views/home/service-guarantee'),
        meta: {
          title: '佳成国际-服务保障',
          menu: 'about',
        },
      },
      {
        path: '/faqs',
        name: 'faqs',
        component: () => import('@/views/home/faqs'),
        meta: {
          title: '佳成国际-常见问题',
          menu: 'about',
        },
      },
      {
        path: '/privacy',
        name: 'privacy',
        component: () => import('@/views/home/privacy'),
        meta: {
          title: '佳成国际-用户隐私政策',
        },
      },
      {
        path: '/user-service',
        name: 'user-service',
        component: () => import('@/views/home/user-service'),
        meta: {
          title: '佳成国际-平台用户服务协议',
        },
      },
    ],
  },
]
